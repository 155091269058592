import { documentTypes } from './documentTypes';

export const checkFileForCompliance = ({
  files,
  types = documentTypes,
  size = 26214400,
}) => {
  return files.reduce(
    (acc, file) => {
      const fileType = file.name.split('.').pop()?.toLowerCase();
      if (types.indexOf(fileType) === -1) {
        acc.type.push(file);
        if (acc.extension.indexOf(fileType) === -1) {
          acc.extension.push(fileType);
        }
      }
      if (file.size > size) {
        acc.size.push(file);
      }
      return acc;
    },
    { size: [], type: [], extension: [] },
  );
};
